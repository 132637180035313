
.pack-el-form {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    .form-img {
        .img-box {
            position: relative;
            width: 100px;
            height: 100px;
            .preview-avatar {
                width: 100%;
                height: 100%;
                z-index: 9;
                transition: all 0.3s;
            }
            .clear-img {
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                border: 13px solid red;
                border-color: red red transparent transparent;
                transition: all 0.3s;
                .clear-button {
                    position: absolute;
                    right: -11px;
                    top: -11px;
                    font-size: 18px;
                    line-height: 13px;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
        .hint {
            margin-top: 10px;
            text-align: start;
            color: #ababbb;
            line-height: normal;
        }
    }
}
::v-deep .pack-form-box {
    .el-form-item {
        flex: 1;
        display: flex;
        margin-right: 0;
        .el-form-item__error {
            margin-left: 14px;
        }
        .el-form-item__label {
            white-space: nowrap;
            color: #1b162a;
        }
        .form-select {
            text-align: start;
            .el-select {
                width: 100%;
            }
            .el-icon-arrow-up:before {
                content: "\e78f";
                color: #342e43;
                font-size: 20px;
            }
        }
    }
}
::v-deep .el-scrollbar__wrap {
    margin-bottom: 0 !important;
}
::v-deep .el-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}
/* ::v-deep .el-input,
::v-deep .el-upload {
    background-color: transparent;
} */
